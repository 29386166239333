import React from "react"
import "./Socialmedia.css"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share"

function Socialmedia({title, description, content, image, url}) {
  console.log("social media", url)
  const getExcerpt = (content, maxLength = 150) => {
    if (!content) return ""
    const div = document.createElement("div")
    div.innerHTML = content
    const plainText = div.textContent || div.innerText || ""
    return plainText.length > maxLength
      ? `${plainText.substring(0, maxLength)}...`
      : plainText
  }

  const excerpt = getExcerpt(description || content)

  const shareContent = {
    facebook: {
      quote: title,
      hashtag: "#KibrislıGazetesi",
    },
    twitter: {
      title: `${title}\n\n${getExcerpt(description || content, 100)}`,
      hashtags: ["KibrislıGazetesi", "Haber"],
      via: "kibrisligazetesi",
    },
    whatsapp: {
      title: `${title}\n\n${excerpt}\n\nDaha fazlası için: ${url}`,
    },
    telegram: {
      title: `${title}\n\n${excerpt}\n\nDaha fazlası için: ${url}`,
    },
  }

  return (
    <div className="Socialmedia">
      <div className="Socialmedia-slider">
        <FacebookShareButton
          url={url}
          quote={shareContent.facebook.quote}
          hashtag={shareContent.facebook.hashtag}
          className="social-icon facebook"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={url}
          title={shareContent.twitter.title}
          hashtags={shareContent.twitter.hashtags}
          via={shareContent.twitter.via}
          className="social-icon twitter"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={url}
          title={shareContent.whatsapp.title}
          separator=" "
          className="social-icon whatsapp"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={url}
          title={shareContent.telegram.title}
          className="social-icon telegram"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default Socialmedia
