import React from "react"
import "./CategoryEtiketi.css"
export default function CategoryEtiketi({headerText}) {
  return (
    <div className="category-dedecterd">
      <div className="category-dedecterd-border">
        <div className="texts-top"></div>
        <div className="texts">{headerText}</div>
      </div>
    </div>
  )
}
