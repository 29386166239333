import React, { useEffect, useState } from "react";
import "./Kunye.css";
import Side from "../../Components/Navbar/PlayersList/Side/Side";
import { Config } from "../../Config/config";
import TitleBox from "../../Components/TitleBox/TitleBox";
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory";
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent";

const ContactInfo = () => (
  <div className="contact-info">
    <h2>İletişim Bilgileri</h2>
    <div className="kunye-iletisim-bilgileri">
      <a
        href="https://www.google.com/maps?q=Yüksel+Altan+Apartmanı,+Mete+Adanır+Cd.+Daire+2,+Girne+0392+KKTC"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>Adres:</strong>
        <span>
          Yüksel Altan Apartmanı, Mete Adanır Cd-Daire 2,Girne 0392 Kuzey Kıbrıs
          Türk Cumhuriyeti
        </span>
      </a>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <a href="tel:+905338880777">
        <strong>Telefon:</strong> <span>+90 (533) 888 07 77</span>
      </a>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <a href="mailto:haber@kibrisligazetesi.com">
        <strong>Mail:</strong>
        <span> haber@kibrisligazetesi.com</span>
      </a>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <a href="tel:+905338881137">
        <strong>Reklam Telefon:</strong>
        <span>+90 (533) 888 11 37</span>
      </a>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <a href="mailto:reklam@kibrisligazetesi.com">
        <strong>Reklam Mail:</strong>
        <span> reklam@kibrisligazetesi.com</span>
      </a>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <p>
        Gazetede yer alan tüm metin ve benzeri içeriğin hakları hiçbir şekilde
        basılı ya da elektronik ortamdan kaynak gösterilse bile izin alınmadan
        kullanılamaz.
      </p>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <p>Tüm yazarlarımızın yazıları kendilerinden sorumludur.</p>
    </div>
    <div className="kunye-iletisim-bilgileri">
      <p>“Bu haber sitesi, gazetecilik meslek ilkelerine uygun davranır”</p>
    </div>
  </div>
);

const CardItem = ({ header, name, mail, image }) => (
  <div className="card-item">
    <h4>{header}</h4>
    <p>{name}</p>
    <p>{mail}</p>
  </div>
);

const Left = () => {
  const [editors, setEditors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Config.ApiBaseUrl}/kunye`, {
          method: "GET",
          headers: {
            Authorization: `Token ${Config.Token}`,
          },
        });
        const data = await response.json();
        //   console.log("kunye", data)
        setEditors(data);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <TopRelatedCategory />
      <div className="Left">
        <div className="Left-ad">
          <div className="kunyeTitleBox">
            <TitleBox title="Künye" />
          </div>
          <div className="writers-line" style={{ marginTop: "15px" }}></div>

          <div className="card-container">
            {editors
              .filter((person) => person.is_editor)
              .reverse()
              .map((person) => (
                <CardItem
                  key={person.id}
                  header={person.title__name || ""}
                  name={person.name}
                  mail={person.email}
                />
              ))}
            <CardItem
              header="Reklam Telefon Numarası"
              mail={
                <a href="tel:+905338881137" className="kunye-telefon-number">
                  +90 (533) 888 11 37
                </a>
              }
            />
          </div>
          <div>
            <ContactInfo />
            <SingleRelatedComponent />
          </div>
        </div>
        <div className="kunye-side">
          <Side />
        </div>
      </div>
    </>
  );
};

export default Left;
